<template>
  <table style="width: 100%;" v-enter-next>
    <tr v-if="cliente">
      <td style="text-align:right;">
        <label>Cliente:</label>
      </td>
      <td colspan="3">
        <s-foreigninput v-model="value.cliente" required style="width: 100%;"
          :url="urlClientes" :tela="47" :number="true" field-cod="cliCod"
          field-cod-style="width: 150px" field-des="pessoa.nome"
          :disabled="!!value.id || isDrsnoopy" />
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Animal:</label>
      </td>
      <td colspan="3">
        <div style="display: flex;">
          <input v-model="value.id" style="width: 150px; text-align: center;"
            placeholder="auto" readonly>
          <input ref="animal" v-model="value.nome" style="flex: 1;" required
            :disabled="isDrsnoopy" placeholder="nome...">
          <select v-model="value.status" style="width: 100px;" required>
            <option value="vivo">vivo</option>
            <option value="óbito">óbito</option>
            <option value="inativo">inativo</option>
          </select>
        </div>
      </td>
    </tr>
    <tr>
      <td style="text-align: right; width: 80px;">
        <label>Espécie:</label>
      </td>
      <td>
        <s-select2 v-model="value.especie" :data="especies" tags
          style="width:100%;" :disabled="isDrsnoopy" placeholder="espécie..." />
      </td>
      <td style="text-align: right; width: 80px;">
        <label>Raça:</label>
      </td>
      <td style="width: 300px;">
        <s-select2 v-model="value.raca" :data="racas" tags style="width:100%;"
          :disabled="!value.especie || isDrsnoopy" placeholder="raça..." />
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Cor:</label>
      </td>
      <td>
        <s-select2 v-model="value.cor" style="width: 100%;" :url="urlCores"
          tela="55" tela-search="56" field-cod="id" field-des="descricao"
          placeholder="cor..." />
      </td>
      <td style="text-align: right;">
        <label>Pelagem:</label>
      </td>
      <td>
        <s-select2 v-model="value.pelagem" :data="pelagens" tags
          style="width:100%;" placeholder="pelagem..." />
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Condição:</label>
      </td>
      <td>
        <s-select2 v-model="value.condicao" style="width: 100%;"
          :url="urlCondicoes" tela="266" tela-search="265" field-cod="id"
          field-des="descricao" placeholder="condição..." />
      </td>
      <td style="text-align: right;">
        <label>Microchip:</label>
      </td>
      <td>
        <input v-model="value.microchip" style="width: 100%;"
          placeholder="microchip...">
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Porte:</label>
      </td>
      <td>
        <s-select2 v-model="value.porte" :data="portes" tags style="width:100%;"
          :disabled="isDrsnoopy" placeholder="porte..." />
      </td>
      <td style="text-align: right;">
        <label>Sexo:</label>
      </td>
      <td>
        <div style="display: flex; justify-content: space-between;">
          <select v-model="value.sexo" style="width: 100px;"
            :disabled="isDrsnoopy">
            <option></option>
            <option value="femea">fêmea</option>
            <option value="macho">macho</option>
            <option value="indefinido">indefinido</option>
          </select>
          <label>
            Castrado:
            <select v-model="value.castrado" style="width: 90px;">
              <option value="false">não</option>
              <option value="true">sim</option>
            </select>
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Peso:</label>
      </td>
      <td colspan="2">
        <div style="display: flex; align-items: center;">
          <s-numberinput v-model="value.peso"
            style="width: 150px; text-align: right;" />
          <div
            style="flex: 1; display: flex; justify-content: space-between; margin-left: 5px;">
            <label>kg</label>
            <label>Comportamento:</label>
          </div>
        </div>
      </td>
      <td>
        <s-select2 v-model="value.comportamento" :data="comportamentos" tags
          style="width: 100%;" placeholder="comportamento..." />
      </td>
    </tr>
    <tr>
      <td style="text-align: right;">
        <label>Nascimento:</label>
      </td>
      <td colspan="2">
        <s-datetimepicker type="date" v-model="value.dataNascimento"
          style="width: 150px; text-align: center" />
        <b><label>{{ idade }}</label></b>
      </td>
      <td rowspan="2">
        <s-image-upload style="max-width: 300px;" v-model="value.imagem"
          title="Imagem" />
      </td>
    </tr>
    <tr>
      <td style="text-align: right; vertical-align: top;">
        <label>Observações:</label>
      </td>
      <td colspan="2">
        <textarea v-model="value.observacoes"
          placeholder="Histórico clínico, doenças crônicas e/ou anteriores à primeira consulta..."
          style="width: 100%; height: 160px;"></textarea>
      </td>
    </tr>
  </table>
</template>

<script>
import animais from "@/utils/animais";

export default {
  name: "s-animal",

  inject: ["hdl"],

  props: {
    value: Object,
    cliente: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    "value.id"() {
      this.$nextTick(() => this.$refs?.animal?.select?.());
    },
  },

  computed: {
    urlClientes() {
      return `${globalThis.Siggma.basePath}/app/clientes/get-by-id`;
    },
    urlCores() {
      return `${globalThis.Siggma.basePath}/app/cores/find-by-term`;
    },
    urlCondicoes() {
      return `${globalThis.Siggma.basePath}/pet-shop/animais-condicoes/find-by-term`;
    },
    racas() {
      if (!this.value.especie) {
        this.value.raca = "";
        return [];
      }

      return animais.getRacas(this.value.especie)
        .map((r) => ({ text: r.nome, id: r.nome }));
    },
    isDrsnoopy() {
      return !!this.value.drsnoopy;
    },
    idade() {
      const data = this.value.dataNascimento;
      if (!data) {
        return "";
      }

      let meses = moment(new Date()).diff(moment(data, "DD/MM/YYYY"), "months");
      if (meses < 13) {
        return meses + (meses > 1 ? " meses" : " mês");
      }

      const anos = Math.floor(meses / 12);
      meses = meses % 12;
      return anos + (anos > 1 ? " anos e " : " ano e ") + meses +
        (meses > 1 ? " meses" : " mês");
    },
  },

  data: () => ({
    comportamentos: animais.comportamentos.map((id) => ({ text: id, id })),
    especies: animais.especies.map((id) => ({ text: id, id })),
    pelagens: animais.pelagens.map((id) => ({ text: id, id })),
    portes: animais.portes.map((id) => ({ text: id, id })),
  }),

  mounted() {
    this.$nextTick(() => this.$refs?.animal?.select?.());
  },
};
</script>